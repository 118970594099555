<template>
  <div class="tc-container">
    <h1 class="grey--text text-center">Terms & conditions</h1>
    <p class="grey--text mt-8 text-center">
      Terms and conditions for a real-time strategy (RTS) game must be
      established to ensure a fair and enjoyable experience for all players. All
      players must agree to abide by the rules of the game, including the use of
      appropriate language, respect for other players, and no cheating or
      exploiting of game mechanics. Players must also agree to not share or
      distribute any game content, including game files, screenshots, or videos.
      Additionally, players must agree to not use any third-party software or
      tools to gain an unfair advantage over other players. Finally, players
      must agree to not harass or threaten other players, and to report any
      violations of the terms and conditions to the game's administrators. By
      agreeing to these terms and conditions, players are agreeing to abide by
      the rules of the game and to respect the rights of other players.
    </p>
    <ol class="mt-8">
      <li v-for="(condition, i) in conditions" :key="i" class="grey--text my-1">
        {{ condition }}
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  data() {
    return {
      conditions: [
        " You must be at least 13 years of age to play this game.",
        " You must not use any cheats, hacks, or other third-party software to gain an unfair advantage in the game.",
        " You must not use any offensive language or behavior in the game.",
        " You must not harass, threaten, or otherwise abuse other players in the game.",
        " You must not exploit any bugs or glitches in the game.",

        " You must not use any automated programs or scripts to play the game.",

        " You must not attempt to sell, trade, or otherwise transfer your account or any in-game items.",

        " You must not use the game for any commercial purpose.",

        " You must not use the game to violate any applicable laws or regulations.",

        " You must not attempt to reverse engineer, decompile, or otherwise modify any part of the game.",
        " You must not use the game to infringe upon the intellectual property rights of any third party.",

        " You must not use the game to engage in any illegal activity.",

        " You must not use the game to distribute any malicious software or content.",

        " You must not use the game to advertise or promote any product or service.",

        " You must not use the game to engage in any activity that is deemed inappropriate by the game's developers.",

        " You must not use the game to violate any of the terms and conditions of this agreement.",

        " You must not use the game to violate any applicable laws or regulations.",

        " You must not use the game to engage in any activity that is deemed inappropriate by the game's developers.",

        " You must not use the game to distribute any malicious software or content.",

        " You must not use the game to advertise or promote any product or service.",

        " You must not use the game to engage in any activity that is deemed inappropriate by the game's developers.",

        " You must not use the game to violate any of the terms and conditions of this agreement.",

        " You must not use the game to violate any applicable laws or regulations.",
        " You must not use the game to engage in any activity that is deemed inappropriate by the game's developers.",

        " You must not use the game to distribute any malicious software or content.",

        " You must not use the game to advertise or promote any product or service.",

        " You must not use the game to engage in any activity that is deemed inappropriate by the game's developers.",

        " You must not use the game to violate any of the terms and conditions of this agreement.",

        " You must not use the game to violate any applicable laws or regulations.",

        " You must not use the game to engage in any activity that is deemed inappropriate by the game's developers.",

        " You must not use the game to distribute any malicious software or content.",

        " You must not use the game to advertise or promote any product or service.",

        " You must not use the game to engage in any activity that is deemed inappropriate by the game's developers.",

        " You must not use the game to violate any of the terms and conditions of this agreement.",

        " You must not use the game to violate any applicable laws or regulations.",

        " You must not use the game to engage in any activity that is deemed inappropriate by the game's developers.",

        " You must not use the game to distribute any malicious software or content.",

        " You must not use the game to advertise or promote any product or service.",

        " You must not use the game to engage in any activity that is deemed inappropriate by the game's developers.",

        " You must not use the game to violate any of the terms and conditions of this agreement.",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.tc-container {
  padding: 100px 10%;
  @media only screen and(max-width : 450px) {
    h1{
      font-size: 20px;
    }
    p,li{
      font-size: 12px;
    }
    
  }
}
</style>
